import { Fragment, FunctionComponent } from 'react'
import type { MediumGalleryResult } from '../data/content/MediumGalleryFragment'
import { Container } from './Container'
import { ImageSizeByArea } from './ImageSizeByArea'
import styles from './JumbotronWithLogos.module.sass'
import { Title } from './Title'
import { Wysiwyg } from './Wysiwyg'

export type JumbotronWithLogosProps = {
	title?: string
	description?: string
	logos?: MediumGalleryResult
}

export const JumbotronWithLogos: FunctionComponent<JumbotronWithLogosProps> = ({
	title,
	description,
	logos,
}) => {
	return (
		<div className={styles.wrapper}>
			<Container size="narrow">
				{title && <Title title={title} heading="secondary" />}
				{description && (
					<div className={styles.description}>
						<Wysiwyg source={description} />
					</div>
				)}
				{logos && (
					<div className={styles.logos}>
						{logos.items.map((logo) => (
							<Fragment key={logo.id}>
								{logo.image && (
									<div className={styles.logo}>
										<ImageSizeByArea image={logo.image} />
									</div>
								)}
							</Fragment>
						))}
					</div>
				)}
			</Container>
		</div>
	)
}
