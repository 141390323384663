import { RichTextRenderer } from '@contember/react-client'
import { FunctionComponent, useRef } from 'react'
import type { CommonPageProps } from '../../pages/[[...page]]'
import { Container } from './Container'
import NoSsr from './NoSsr'
import { PortfolioList } from './PortfolioList'
import styles from './PortfolioPage.module.sass'

export type PortfolioPageProps = NonNullable<CommonPageProps['page']['portfolioPage']>

export const PortfolioPage: FunctionComponent<PortfolioPageProps> = ({ title, text, yearList }) => {
	const content = useRef<HTMLDivElement>(null)

	return (
		<div className={styles.wrapper}>
			<Container>
				<div className={styles.main}>
					<div className={styles.content}>
						<div className={styles.contentIn} ref={content}>
							{title && <h1 className={styles.title}>{title}</h1>}
							{text && (
								<p className={styles.text}>
									<RichTextRenderer source={text} />
								</p>
							)}
						</div>
					</div>
					{yearList && (
						<div className={styles.portfolio}>
							<NoSsr>
								<PortfolioList items={yearList} viewType="page" />
							</NoSsr>
						</div>
					)}
				</div>
			</Container>
		</div>
	)
}
