import { FunctionComponent, useEffect, useRef, useState } from 'react'
import type { VideoResult } from '../data/content/VideoFragment'
import { Container } from './Container'
import styles from './CoverVideo.module.sass'

export type CoverVideoProps = {
	video: VideoResult
}

export const CoverVideo: FunctionComponent<CoverVideoProps> = ({ video }) => {
	const refVideo = useRef<HTMLVideoElement>(null)
	const [isLoading, setLoading] = useState(true)

	useEffect(() => {
		if (!refVideo.current) {
			return
		}

		refVideo.current.onplaying = () => setLoading(false)
	}, [])

	return (
		<div className={styles.wrapper}>
			<Container size="wide" disableGutters>
				<div className={styles.main}>
					{isLoading && (
						<div className={styles.is_loading}>
							<div className={styles.loadingElement}></div>
						</div>
					)}
					<video
						className={styles.video}
						poster={video.poster?.url}
						autoPlay
						muted
						loop
						playsInline
						ref={refVideo}>
						<source src={video.src} type={video.type} />
					</video>
				</div>
			</Container>
		</div>
	)
}
