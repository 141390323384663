import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import type { CommonPageProps } from '../../pages/[[...page]]'
import { usePageLinks } from '../contexts/PageLinksContext'
import { Container } from './Container'
import styles from './Header.module.sass'
import type { LayoutProps } from './Layout'
import { SocialLinks } from './SocialLinks'

export type HeaderProps = CommonPageProps['header'] & { pageType?: LayoutProps['pageType'] }

export const Header: FunctionComponent<HeaderProps> = ({
	logo,
	socialLinks,
	pageType,
	links,
	contactAnchor,
}) => {
	const allLinks = usePageLinks()

	return (
		<header className={clsx(styles.wrapper, styles[`is_${pageType}`])}>
			<Container>
				<div className={styles.main}>
					<div className={styles.column}>
						<ul className={styles.links}>
							{pageType === 'portfolioPage' && (
								<li className={styles.linksItem}>
									<a href={allLinks.homePage} className={styles.link}>
										Homepage
									</a>
								</li>
							)}
						</ul>
					</div>
					{logo && (
						<a href={allLinks.homePage}>
							<div
								className={styles.logo}
								style={{
									'--Header-logo-width': logo.width,
									'--Header-logo-height': logo.height,
								}}>
								<Image src={logo.url} layout="fill" objectFit="cover" alt={logo.alt ?? ''} />
							</div>
						</a>
					)}
					<div className={clsx(styles.column, styles.is_right)}>
						<ul className={styles.links}>
							{links.map((item) => (
								<li key={item.id} className={styles.linksItem}>
									<Link href={item.url}>
										<a className={styles.link}>{item.url.replace('/', '')}</a>
									</Link>
								</li>
							))}
							{pageType === 'homePage' && contactAnchor ? (
								<li className={styles.linksItem}>
									<a href={'#' + contactAnchor} className={styles.link}>
										Contact
									</a>
								</li>
							) : null}
						</ul>
						<div className={styles.socialLinks}>
							{socialLinks && <SocialLinks {...socialLinks} />}
						</div>
					</div>
				</div>
			</Container>
		</header>
	)
}
