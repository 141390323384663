import clsx from 'clsx'
import Image from 'next/image'
import { Fragment, FunctionComponent, useState } from 'react'
import type { ShowYearResult } from '../data/content/ShowYearFragment'
import { ContemberInternalLink } from './ContemberLink'
import styles from './PortfolioList.module.sass'
import { Wysiwyg } from './Wysiwyg'

export type PortfolioListProps = {
	items: ShowYearResult[]
	viewType?: 'block' | 'page'
}

export const PortfolioList: FunctionComponent<PortfolioListProps> = ({
	items,
	viewType = 'block',
}) => {
	const [isHoverShowItemId, setHoverShowItemId] = useState<string | null>(null)
	const randomNumber = Math.floor(Math.random() * 40)
	const posterHorizontalPosition = randomNumber % 2 === 1 ? styles.is_left : styles.is_right
	const posterMaxWidth = 400

	return (
		<div className={clsx(styles.wrapper, styles[`is_${viewType}`])}>
			<div className={styles.yearList}>
				{items.map((yearItem) => {
					return (
						yearItem.showList.length > 0 && (
							<Fragment key={yearItem.name}>
								<div className={styles.yearSection}>
									<h3 className={styles.yearLabel}>{yearItem.name}</h3>
									<div className={styles.showList}>
										{yearItem.showList.map((showItem) => (
											<ContemberInternalLink key={showItem.id} link={showItem.link}>
												<a className={styles.showLink}>
													<div
														className={styles.showItem}
														onMouseOver={() => {
															setHoverShowItemId(showItem.id)
														}}
														onMouseLeave={() => {
															setHoverShowItemId(null)
														}}
														onFocus={() => setHoverShowItemId(showItem.id)}>
														<h4 className={styles.showTitle}>{showItem.title}</h4>
														{viewType === 'page' && (
															<div className={styles.showDescription}>
																<Wysiwyg source={showItem.description} />
															</div>
														)}
													</div>
												</a>
											</ContemberInternalLink>
										))}
									</div>
									{yearItem.showList && (
										<div className={styles.posters}>
											{yearItem.showList.map(
												(showItem) =>
													showItem.poster && (
														<div
															key={showItem.id}
															className={clsx(
																styles.poster,
																showItem.id === isHoverShowItemId && styles.is_hovered,
																posterHorizontalPosition,
																styles[`is_type_${showItem.posterType}`],
																styles[`is_sizeType_${showItem.posterSizeType}`]
															)}
															style={{
																'--PortfolioList-poster-width': showItem.poster.width,
																'--PortfolioList-poster-height': showItem.poster.height,
																'--PortfolioList-poster-maxWidth':
																	showItem.poster?.width && showItem.poster.width > posterMaxWidth
																		? posterMaxWidth + 'px'
																		: `${showItem.poster.width}px`,
																'--PortFolio-poster-percentagePosition':
																	randomNumber.toString() + '%',
															}}>
															<Image
																src={showItem.poster.url}
																layout="fill"
																objectFit="cover"
																alt={showItem.poster.alt ?? ''}
															/>
														</div>
													)
											)}
										</div>
									)}
								</div>
							</Fragment>
						)
					)
				})}
			</div>
		</div>
	)
}
