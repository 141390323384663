import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import Image from 'next/image'
import type { FunctionComponent, ReactNode } from 'react'
import type { CommonPageProps } from '../../pages/[[...page]]'
import type { LinkResult } from '../data/content/LinkFragment'
import { BreakableContent } from './BreakableContent'
import { Container } from './Container'
import { ContemberLink } from './ContemberLink'
import styles from './Footer.module.sass'
import { SocialLinks } from './SocialLinks'
import { Wysiwyg } from './Wysiwyg'

export type FooterProps = CommonPageProps['footer']

export const Footer: FunctionComponent<FooterProps> = ({
	title,
	address,
	generalContact,
	members,
	logo,
	info,
	socialLinks,
	contactAnchor,
}) => {
	return (
		<footer id={contactAnchor} className={styles.wrapper}>
			<Container>
				<div className={clsx(styles.section, styles.is_above)}>
					{title && <h3 className={styles.title}>{title}</h3>}
					<div className={styles.sectionIn}>
						{(address?.label || address?.address) && (
							<div className={clsx(styles.address, styles.column)}>
								<h4 className={styles.addressLabel}>{address.label}</h4>
								<address className={styles.addressInfo}>
									<FooterAddressLink link={address.addressLink}>
										{address.address && (
											<BreakableContent text={address.address} isHoverableLink="appears" />
										)}
									</FooterAddressLink>
								</address>
							</div>
						)}
						<div className={styles.contactMembers}>
							{generalContact && (
								<div className={clsx(styles.column, styles.generalContact)}>
									<h4 className={styles.generalContactLabel}>{generalContact.label}</h4>
									{generalContact.info && (
										<div className={styles.generalContactInfo}>
											<RichTextRenderer source={generalContact.info} />
										</div>
									)}
									<div>
										<a
											href={`mailto:${generalContact.email}`}
											className={styles.generalContactLink}>
											{generalContact.email}
										</a>
									</div>
								</div>
							)}
							{members &&
								members.items.map((item) => (
									<div key={item.id} className={clsx(styles.member, styles.column)}>
										{item.member?.position && (
											<h4 className={styles.memberPosition}>{item.member.position}</h4>
										)}
										{(item.member?.email || item.member?.telephone) && (
											<div className={styles.memberInfo}>
												{item.member?.email && (
													<div>
														<a
															href={`mailto:${item.member.email}`}
															className={styles.memberContactLink}>
															{item.member.email}
														</a>
													</div>
												)}
												{item.member?.telephone && (
													<div>
														<a
															href={`tel:${item.member.telephone}`}
															className={styles.memberContactLink}>
															{item.member.telephone}
														</a>
													</div>
												)}
											</div>
										)}
									</div>
								))}
						</div>
					</div>
				</div>
				<div className={clsx(styles.section, styles.is_below)}>
					<div className={styles.sectionIn}>
						<div className={styles.socialsWrapper}>
							{socialLinks && (
								<SocialLinks items={socialLinks.items} iconSize="small" isLabelVisible />
							)}
						</div>
						<div className={styles.logoWrapper}>
							{logo && (
								<div
									className={styles.logo}
									style={{
										'--Footer-logo-width': logo.width,
										'--Footer-logo-height': logo.height,
									}}>
									<Image src={logo.url} layout="fill" objectFit="cover" alt={logo.alt ?? ''} />
								</div>
							)}
							{info && (
								<div className={styles.info}>
									<Wysiwyg source={info} />
								</div>
							)}
						</div>
						<div className={styles.copyright}>
							Produced by{' '}
							<a className={styles.copyrightLink} href="https://www.mangoweb.cz/">
								manGoweb
							</a>
						</div>
					</div>
				</div>
			</Container>
		</footer>
	)
}

const FooterAddressLink: FunctionComponent<{ link?: LinkResult; children: ReactNode }> = ({
	link,
	children,
}) => {
	if (link) {
		return (
			<ContemberLink link={link}>
				<a className={styles.addressLink}>{children}</a>
			</ContemberLink>
		)
	}
	return <>{children}</>
}
