import React from 'react'
import type { CommonPageProps } from '../../pages/[[...page]]'

export const PageLinksContext = React.createContext<null | CommonPageProps['pageLinks']>(null)

export function usePageLinks() {
	const value = React.useContext(PageLinksContext)

	if (!value) {
		throw new Error()
	}

	return value
}
