import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { Container } from './Container'
import styles from './MessagePage.module.sass'

export type MessagePageProps = {
	title?: string
	returnLinkText?: string
}

export const MessagePage: FunctionComponent<MessagePageProps> = ({ title, returnLinkText }) => {
	return (
		<Container>
			<div className={styles.wrapper}>
				<h1 className={styles.title}>
					<div className={styles.titleStatus}>404</div>
					{title}
				</h1>
				<Link href="/">
					<a className={styles.returnLink}>{returnLinkText}</a>
				</Link>
			</div>
		</Container>
	)
}
