import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { useIsOverflowingString } from '../../utils/useIsOverflowingString'
import { Container } from './Container'
import styles from './Title.module.sass'

export type TitleProps = {
	title: string
	heading?: 'primary' | 'secondary'
}

export const Title: FunctionComponent<TitleProps> = ({ title, heading = 'primary' }) => {
	const isStringOverflowing = useIsOverflowingString(title)
	return (
		<div
			className={clsx(
				styles.wrapper,
				styles[`is_${heading}`],
				isStringOverflowing && styles.is_overflowing
			)}>
			<Container>
				{heading === 'primary' ? (
					<h1 className={styles.title}>{title}</h1>
				) : heading === 'secondary' ? (
					<h2 className={styles.title}>{title}</h2>
				) : null}
			</Container>
		</div>
	)
}
