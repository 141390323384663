import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { ContentBlockResult } from '../data/content/ContentBlockFragment'
import { Collaboration } from './Collaboration'
import styles from './ContentBlocks.module.sass'
import { CoverVideo } from './CoverVideo'
import { JumbotronWithLogos } from './JumbotronWithLogos'
import { Members } from './Members'
import { PortfolioBlock } from './PortfolioBlock'
import { Title } from './Title'

type ContentBlocksProps = {
	blocks: ContentBlockResult[]
}

export const ContentBlocks: FunctionComponent<ContentBlocksProps> = ({ blocks }) => {
	return (
		<>
			{blocks.map((block) => {
				const blocksElement = {
					coverVideo: block.video && <CoverVideo video={block.video} />,
					collaboration: (block.primaryText ||
						block.secondaryText ||
						block.gallery ||
						block.link) && (
						<Collaboration
							title={block.primaryText}
							description={block.secondaryText}
							images={block.gallery}
							link={block.link}
						/>
					),
					members: block.members && <Members title={block.primaryText} members={block.members} />,
					jumbotronWithLogos: (block.primaryText || block.secondaryText || block.gallery) && (
						<JumbotronWithLogos
							title={block.primaryText}
							description={block.secondaryText}
							logos={block.gallery}
						/>
					),
					portfolio: block.portfolio && (
						<PortfolioBlock items={block.portfolio.items} link={block.link} />
					),
					title: block.primaryText && <Title title={block.primaryText} />,
				}
				return (
					<section key={block.id} className={clsx(styles.section, styles[`is_${block.type}`])}>
						{blocksElement[block.type]}
					</section>
				)
			})}
		</>
	)
}
