import type { FunctionComponent } from 'react'
import { isDefined } from '../../utils/isDefined'
import type { LinkResult } from '../data/content/LinkFragment'
import type { ShowYearListResult } from '../data/content/ShowYearListFragment'
import { Container } from './Container'
import { ContemberLink } from './ContemberLink'
import NoSsr from './NoSsr'
import styles from './PortfolioBlock.module.sass'
import { PortfolioList } from './PortfolioList'

export type PortfolioBlockProps = ShowYearListResult & {
	link?: LinkResult
}

export const PortfolioBlock: FunctionComponent<PortfolioBlockProps> = ({ items, link }) => {
	return (
		<div className={styles.wrapper}>
			<Container>
				<div className={styles.main}>
					<NoSsr>
						<PortfolioList
							items={items.map((item) => item.showYear).filter(isDefined)}
							viewType="block"
						/>
					</NoSsr>
					{link && (
						<div className={styles.actions}>
							<ContemberLink link={link}>
								<a className={styles.link}>{link.title}</a>
							</ContemberLink>
						</div>
					)}
				</div>
			</Container>
		</div>
	)
}
