import { LightboxSource, useLightbox } from '@mangoweb/react-fslightbox'
import { useMemo } from 'react'
import type { MediumGalleryResult } from '../app/data/content/MediumGalleryFragment'
import { MediumType } from '../generated/content'

export const useGallery = (item: MediumGalleryResult['items']) => {
	const gallery = useMemo<LightboxSource[]>(
		() =>
			item.map((item) => {
				if (item.type === MediumType.image) {
					return {
						source: item.image?.url ?? '',
						type: 'image',
						caption: item.image?.alt,
					}
				} else if (item.type === MediumType.video) {
					return {
						source: item.video?.src ?? '',
						type: 'video',
						caption: item.video?.poster?.alt,
					}
				}
			}),
		[item]
	)

	const lightbox = useLightbox(gallery)

	return lightbox
}
