import clsx from 'clsx'
import Image from 'next/image'
import { FunctionComponent, useCallback } from 'react'
import type { LinkResult } from '../data/content/LinkFragment'
import type { SocialLinkListResult } from '../data/content/SocialLinkListFragment'
import { ContemberLink } from './ContemberLink'
import styles from './SocialLinks.module.sass'

export type SocialLinksProps = SocialLinkListResult & {
	isLabelVisible?: boolean
	iconSize?: 'small' | 'normal'
}

export const SocialLinks: FunctionComponent<SocialLinksProps> = ({
	items,
	isLabelVisible,
	iconSize = 'normal',
}) => {
	const anchorWrapper = useCallback((children: React.ReactNode, link?: LinkResult) => {
		if (link) {
			return (
				<ContemberLink link={link}>
					<a className={styles.link}>{children}</a>
				</ContemberLink>
			)
		} else {
			return children
		}
	}, [])
	return (
		<div className={styles.wrapper}>
			<div className={styles.list}>
				{items.map((item) => (
					<div key={item.id} className={styles.item}>
						{item.socialLink?.icon &&
							anchorWrapper(
								<>
									<div
										className={clsx(styles.image, styles[`is_${iconSize}`])}
										style={{
											'--SocialLinks-image-width': item.socialLink.icon.width,
											'--SocialLinks-image-height': item.socialLink.icon.height,
										}}>
										<Image
											src={item.socialLink.icon.url}
											layout="fill"
											objectFit="cover"
											alt={item.socialLink.icon.alt ?? ''}
										/>
									</div>
									{isLabelVisible && <div className={styles.label}>{item.socialLink.name}</div>}
								</>,
								item.socialLink.link
							)}
					</div>
				))}
			</div>
		</div>
	)
}
