import type { FunctionComponent } from 'react'
import type { CommonPageProps } from '../../pages/[[...page]]'
import { ContentBlocks } from './ContentBlocks'
import styles from './Homepage.module.sass'

export type HomePageProps = NonNullable<CommonPageProps['page']['homePage']>

export const HomePage: FunctionComponent<HomePageProps> = ({ blocks }) => {
	return (
		<div className={styles.wrapper}>
			<ContentBlocks blocks={blocks} />
		</div>
	)
}
