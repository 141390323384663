import Image from 'next/image'
import { Fragment, FunctionComponent, useState } from 'react'
import { useParallax } from '../../utils/useParallax'
import type { MemberListResult } from '../data/content/MemberListFragment'
import { Container } from './Container'
import styles from './Members.module.sass'
import { Title } from './Title'

export type MembersProps = {
	title?: string
	members: MemberListResult
}

export const Members: FunctionComponent<MembersProps> = ({ title, members }) => {
	const [wrapper, setWrapper] = useState<HTMLDivElement | null>(null)
	const offset = useParallax(wrapper)
	return (
		<div className={styles.wrapper}>
			<Container>
				{title && (
					<div className={styles.title}>
						<Title heading="secondary" title={title} />
					</div>
				)}
				<div className={styles.list} ref={setWrapper} style={{ '--Members-offset': offset }}>
					{members.items.map((item) => (
						<Fragment key={item.id}>
							{item.member && (
								<div className={styles.item}>
									<div className={styles.nameWrapper}>
										<h3 className={styles.name}>
											{item.member.firstName && (
												<div className={styles.firstName}>{item.member.firstName}</div>
											)}
											<div className={styles.lastName}>{item.member.lastName}</div>
										</h3>
										{item.member.position && (
											<div className={styles.position}>{item.member.position}</div>
										)}
									</div>
									<div className={styles.contentWrapper}>
										<div className={styles.image}>
											{item.member.image && (
												<Image
													src={item.member.image.url}
													layout="fill"
													objectFit="cover"
													alt={item.member.image.alt}
												/>
											)}
										</div>
										{(item.member.email || item.member.telephone) && (
											<ul className={styles.contact}>
												<li>
													<a href={`mailto:${item.member.email}`} className={styles.contactLink}>
														{item.member.email}
													</a>
												</li>
												<li>
													<a href={`tel:${item.member.telephone}`} className={styles.contactLink}>
														{item.member.telephone}
													</a>
												</li>
											</ul>
										)}
									</div>
								</div>
							)}
						</Fragment>
					))}
				</div>
			</Container>
		</div>
	)
}
