import clsx from 'clsx'
import Image from 'next/image'
import { Fragment, FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import { MediumType } from '../../generated/content'
import type { CommonPageProps } from '../../pages/[[...page]]'
import { useGallery } from '../../utils/useGallery'
import type { ImageResult } from '../data/content/ImageFragment'
import type { MediumGalleryResult } from '../data/content/MediumGalleryFragment'
import type { MediumGalleryItemResult } from '../data/content/MediumGalleryItemFragment'
import type { VideoResult } from '../data/content/VideoFragment'
import { Icon } from './Icon'
import styles from './ShowPage.module.sass'
import { ShowPageCarouselNavigationNavigation } from './ShowPageCarouselNavigation'
import { Wysiwyg } from './Wysiwyg'

export type ShowPageProps = NonNullable<CommonPageProps['page']['showPage']>
const carouselCardMaxWidth = 500

export const ShowPage: FunctionComponent<ShowPageProps> = ({
	title,
	synopsis,
	poster,
	gallery,
	production,
	directors,
	starrings,
	categoryYear,
}) => {
	const carouselElements = useRef<HTMLDivElement>(null)
	const media = useMemo<MediumGalleryResult['items']>(() => {
		const posterItem: MediumGalleryItemResult | undefined = poster && {
			id: poster.id,
			image: poster,
			type: MediumType.image,
		}
		const result: MediumGalleryResult['items'] = [...(gallery?.items ?? [])]
		if (posterItem) {
			result.unshift(posterItem)
		}
		return result
	}, [gallery?.items, poster])

	const lightbox = useGallery(media)

	const scroll = (direction: 1 | -1) => {
		if (!carouselElements.current) {
			return
		}

		const scrollSlideOffset = direction * carouselCardMaxWidth

		carouselElements.current.scrollBy({
			left: scrollSlideOffset,
			behavior: 'smooth',
		})
	}

	const [showPreviousButton, setShowPreviousButton] = useState(false)
	const [showNextButton, setShowNextButton] = useState(false)

	const onSlideChange = () => {
		if (carouselElements.current === null) {
			return
		}

		setShowPreviousButton(true)
		setShowNextButton(true)
		if (carouselElements.current.scrollLeft === 0) {
			setShowPreviousButton(false)
		}
		if (
			carouselElements.current.scrollLeft ===
			(carouselElements.current.scrollWidth ?? 0) - (carouselElements.current.clientWidth ?? 0)
		) {
			setShowNextButton(false)
		}
	}

	useEffect(() => {
		if (carouselElements.current === null) {
			return
		}

		onSlideChange()

		window.addEventListener('resize', onSlideChange)
		return () => {
			window.removeEventListener('resize', onSlideChange)
		}
	}, [])

	return (
		<div className={styles.wrapper}>
			<div className={styles.hero}>
				<div
					ref={carouselElements}
					className={clsx(styles.carousel, styles.is_desktop)}
					onScroll={() => onSlideChange()}>
					<div className={clsx(styles.slide, styles.is_content)}>
						<div className={styles.heroIn}>
							<div className={styles.content}>
								<h2 className={styles.title}>{title}</h2>
								{synopsis && (
									<div className={styles.synopsis}>
										<Wysiwyg source={synopsis} />
									</div>
								)}
								<div className={styles.informations}>
									<Informations
										production={production}
										directors={directors}
										starrings={starrings}
										categoryYear={categoryYear}
									/>
								</div>
							</div>
							{poster && (
								<ShowPageSlide
									type="image"
									image={poster}
									onClick={() => lightbox?.open(0)}
									isMobileHidden
								/>
							)}
						</div>
					</div>
					{media.map((item, index) => (
						<Fragment key={item.id}>
							{item.image ? (
								<ShowPageSlide
									type="image"
									image={item.image}
									onClick={() => lightbox?.open(index)}
									isDesktopHidden={index === 0}
								/>
							) : item.video ? (
								<ShowPageSlide
									type="video"
									video={item.video}
									onClick={() => lightbox?.open(index)}
									isDesktopHidden={index === 0}
								/>
							) : null}
						</Fragment>
					))}
				</div>
				{media.length > 1 && (
					<div className={styles.navigation}>
						<ShowPageCarouselNavigationNavigation
							onPreviousClick={() => scroll(-1)}
							onNextClick={() => scroll(1)}
							isPreviousButtonVisible={showPreviousButton}
							isNextButtonVisible={showNextButton}
						/>
					</div>
				)}
			</div>
			<div className={clsx(styles.carousel, styles.is_mobile)}>
				{media.map((item, index) => (
					<Fragment key={item.id}>
						{item.image ? (
							<ShowPageSlide
								type="image"
								image={item.image}
								onClick={() => lightbox?.open(index)}
							/>
						) : item.video ? (
							<ShowPageSlide
								type="video"
								video={item.video}
								onClick={() => lightbox?.open(index)}
							/>
						) : null}
					</Fragment>
				))}
			</div>
		</div>
	)
}

type ShowPageSlideProps = {
	isMobileHidden?: boolean
	isDesktopHidden?: boolean
	onClick?: React.MouseEventHandler<HTMLButtonElement>
} & (ShowPageSlideImageProps | ShowPageSlideVideoProps)

type ShowPageSlideImageProps = {
	type: 'image'
	image: ImageResult
}

type ShowPageSlideVideoProps = {
	type: 'video'
	video: VideoResult
}

const ShowPageSlide: FunctionComponent<ShowPageSlideProps> = (props) => {
	let width = 300
	let height = 300
	switch (props.type) {
		case 'image':
			width = props.image.width
				? props.image.width > carouselCardMaxWidth
					? carouselCardMaxWidth
					: props.image.width
				: width

			height =
				props.image.height && props.image.width
					? props.image.width > carouselCardMaxWidth
						? (props.image.height / props.image.width) * carouselCardMaxWidth
						: props.image.height
					: height
			break
		case 'video':
			width = props.video.poster?.width
				? props.video.poster.width > carouselCardMaxWidth
					? carouselCardMaxWidth
					: props.video.poster.width
				: 320

			height =
				props.video.poster?.height && props.video.poster.width
					? props.video.poster.width > carouselCardMaxWidth
						? (props.video.poster.height / props.video.poster.width) * carouselCardMaxWidth
						: props.video.poster.height
					: 180
			break
	}

	return (
		<div
			className={clsx(
				styles.slide,
				props.isMobileHidden && styles.is_mobile_hidden,
				props.isDesktopHidden && styles.is_desktop_hidden
			)}
			style={{
				'--ShowPage-image-width': width,
				'--ShowPage-image-height': height,
			}}>
			<button
				title={props.type === 'image' ? 'image' : 'video'}
				type="button"
				onClick={props.onClick}
				className={styles.slideIn}>
				{props.type === 'image' ? (
					<ShowPageSlideImage image={props.image} />
				) : props.type === 'video' ? (
					<ShowPageSlideVideo video={props.video} />
				) : null}
			</button>
			<button type="button" onClick={props.onClick} className={styles.button}>
				Full resolution
			</button>
		</div>
	)
}

const ShowPageSlideImage: FunctionComponent<{ image?: ImageResult; isVideo?: boolean }> = ({
	image,
	isVideo = false,
}) => {
	return (
		<div className={styles.image}>
			{image && <Image src={image.url} layout="fill" objectFit="cover" alt={image.alt ?? ''} />}
			{isVideo && (
				<div className={styles.playIcon}>
					<Icon name="playIcon" />
				</div>
			)}
		</div>
	)
}

const ShowPageSlideVideo: FunctionComponent<{ video: VideoResult }> = ({ video }) => {
	return <ShowPageSlideImage image={video.poster} isVideo />
}

type InformationsProps = {
	production: ShowPageProps['production']
	directors: ShowPageProps['directors']
	starrings: ShowPageProps['starrings']
	categoryYear: ShowPageProps['categoryYear']
}

const Informations: FunctionComponent<InformationsProps> = ({
	production,
	directors,
	starrings,
	categoryYear,
}) => {
	return (
		<>
			{production && (
				<div className={styles.column}>
					<h3 className={styles.columnLabel}>Production</h3>
					<div className={styles.columnDescription}>{production}</div>
				</div>
			)}
			{directors && (
				<div className={styles.column}>
					<h3 className={styles.columnLabel}>Director</h3>
					<div className={styles.columnDescription}>{directors}</div>
				</div>
			)}
			{starrings && (
				<div className={styles.column}>
					<h3 className={styles.columnLabel}>Starring</h3>
					<div className={styles.columnDescription}>{starrings}</div>
				</div>
			)}
			{categoryYear && (
				<div className={styles.column}>
					<h3 className={styles.columnLabel}>Year</h3>
					<div className={styles.columnDescription}>{categoryYear.name}</div>
				</div>
			)}
		</>
	)
}
