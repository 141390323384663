import dynamic from 'next/dynamic'
import type { FunctionComponent } from 'react'

type NoSsrProps = {
	children: React.ReactNode
}

const NoSsr: FunctionComponent<NoSsrProps> = ({ children }) => <>{children}</>

export default dynamic(() => Promise.resolve(NoSsr), {
	ssr: false,
})
