import type { FunctionComponent } from 'react'
import type { CommonPageProps } from '../../pages/[[...page]]'
import { Footer, FooterProps } from './Footer'
import { Header, HeaderProps } from './Header'
import styles from './Layout.module.sass'

export type LayoutProps = {
	pageType: Pick<CommonPageProps, 'pageType'>['pageType']
	header: HeaderProps
	footer: FooterProps
	children: React.ReactNode
}

export const Layout: FunctionComponent<LayoutProps> = ({ pageType, header, footer, children }) => {
	return (
		<div className={styles.wrapper}>
			<Header {...header} pageType={pageType} />
			<main>{children}</main>
			{pageType !== 'showPage' && <Footer {...footer} />}
		</div>
	)
}
