import type { GetStaticPaths, InferGetStaticPropsType } from 'next'
import Head from 'next/head'
import { Error404Page } from '../app/components/Error404Page'
import { HomePage } from '../app/components/Homepage'
import { Layout } from '../app/components/Layout'
import { PortfolioPage } from '../app/components/PortfolioPage'
import { Seo } from '../app/components/Seo'
import { ShowPage } from '../app/components/ShowPage'
import { PageLinksContext } from '../app/contexts/PageLinksContext'
import { FooterFragment } from '../app/data/content/FooterFragment'
import { HeaderFragment } from '../app/data/content/HeaderFragment'
import { HomePageDetailFragment } from '../app/data/content/HomePageDetailFragment'
import { PageLinksFragment } from '../app/data/content/PageLinksFragment'
import { PortfolioPageDetailFragment } from '../app/data/content/PortfolioPageDetailFragment'
import { RedirectFragment } from '../app/data/content/RedirectFragment'
import { SeoFragment } from '../app/data/content/SeoFragment'
import { ShowPageDetailFragment } from '../app/data/content/ShowPageDetailFragment'
import { ShowYearFragment } from '../app/data/content/ShowYearFragment'
import { One, OrderDirection, Thunder } from '../generated/content'
import {
	RespondWithNotFound,
	RespondWithRedirect,
	staticPropsHandler,
} from '../libs/next/api/handlers'
import { getZeusConnection } from '../libs/next/loaders/graphql'

export type CommonPageProps = InferGetStaticPropsType<typeof getStaticProps>

export default function CommonPage({
	pageType,
	header,
	footer,
	page,
	pageLinks,
	seo: { canonicalUrl, seo },
}: CommonPageProps) {
	return (
		<>
			<Head>
				<link rel="icon" href="/favicon.ico" />
				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
				<link rel="manifest" href="/webmanifest.json" />
				<meta name="msapplication-TileColor" content="#FFFFFF" />

				<meta property="og:image" content="/og-image.png" />
				<meta property="og:image:width" content="1304" />
				<meta property="og:image:height" content="676" />
				<meta name="theme-color" content="#E5E5E5" />
			</Head>
			<Seo canonicalUrl={canonicalUrl} seo={seo} />

			<PageLinksContext.Provider value={pageLinks}>
				<Layout pageType={pageType} header={header} footer={footer}>
					{page.homePage ? (
						<HomePage {...page.homePage} />
					) : page.portfolioPage ? (
						<PortfolioPage {...page.portfolioPage} />
					) : page.showPage ? (
						<ShowPage {...page.showPage} />
					) : (
						<Error404Page />
					)}
				</Layout>
			</PageLinksContext.Provider>
		</>
	)
}

export const getStaticPaths: GetStaticPaths = async (context) => {
	const content = getZeusConnection(Thunder, 'content')

	const links = await content.query({
		listLinkable: [
			{
				filter: {
					redirect: {
						id: {
							isNull: true,
						},
					},
				},
			},
			{
				url: true,
			},
		],
	})

	return {
		paths: links.listLinkable.map((linkable) => {
			const urlParts = linkable.url.split('/').filter((string) => string !== '')

			let locale = context.defaultLocale

			if (context.locales?.includes(urlParts[0])) {
				locale = urlParts.shift()
			}

			return {
				params: {
					page: urlParts,
				},
				locale,
			}
		}),
		fallback: 'blocking',
	}
}

export const getStaticProps = staticPropsHandler(async (context) => {
	const content = getZeusConnection(Thunder, 'content')

	const url = (() => {
		const source = context.params?.page ?? []
		const parts = Array.isArray(source) ? source : [source]
		return `/${parts.join('/')}`
	})()

	const dataFromContember = await content.query({
		listLinkable: [
			{
				filter: {
					showPage: {
						id: {
							isNull: true,
						},
					},
					redirect: {
						id: {
							isNull: true,
						},
					},
				},
			},
			{
				id: true,
				url: true,
			},
		],
		getLinkable: [
			{
				by: {
					url: url,
				},
			},
			{
				url: true,
				homePage: [{}, HomePageDetailFragment()],
				portfolioPage: [{}, PortfolioPageDetailFragment()],
				showPage: [{}, ShowPageDetailFragment()],
				redirect: [{}, RedirectFragment()],
			},
		],
		getHeader: [{ by: { unique: One.One } }, HeaderFragment()],
		getFooter: [{ by: { unique: One.One } }, FooterFragment()],
		getSettings: [
			{ by: { unique: One.One } },
			{
				seo: [{}, SeoFragment()],
			},
		],
		...PageLinksFragment(),
	})

	const redirectUrl = dataFromContember.getLinkable?.redirect?.target?.url

	if (redirectUrl) {
		throw new RespondWithRedirect({ destination: redirectUrl, permanent: false })
	}

	const homePage = await (async () => {
		const initial = dataFromContember.getLinkable?.homePage

		if (!initial) {
			return null
		}

		return initial
	})()

	const portfolioPage = await (async () => {
		const initial = dataFromContember.getLinkable?.portfolioPage

		if (!initial) {
			return null
		}

		const { listShowYear } = await content.query({
			listShowYear: [
				{
					orderBy: [
						{
							name: OrderDirection.desc,
						},
					],
				},
				ShowYearFragment(),
			],
		})

		return { ...initial, yearList: listShowYear }
	})()

	const showPage = await (async () => {
		const initial = dataFromContember.getLinkable?.showPage

		if (!initial) {
			return null
		}

		return initial
	})()

	const page = {
		homePage,
		portfolioPage,
		showPage,
	}

	const pageLinks = {
		homePage: dataFromContember.getHomePage?.link?.url ?? '/',
		portfolioPage: dataFromContember.getPortfolioPage?.link?.url ?? '/',
	}

	const currentPage = Object.values(page).find((page) => page !== null)

	if (!currentPage) {
		throw new RespondWithNotFound('Page not found')
	}

	const contactAnchor = dataFromContember.getFooter?.members?.items ? 'contact' : undefined

	const footer = {
		...dataFromContember.getFooter,
		contactAnchor: contactAnchor,
	}

	const header = {
		...dataFromContember.getHeader,
		links: dataFromContember.listLinkable ?? undefined,
		contactAnchor: contactAnchor,
	}

	const canonicalUrl = (() => {
		const url = dataFromContember.getLinkable?.url
		if (!url) {
			return null
		}
		return (process.env.WEB_ROOT_URL ?? '') + url
	})()

	const pageType = page.homePage
		? ('homePage' as const)
		: page.portfolioPage
		? ('portfolioPage' as const)
		: page.showPage
		? ('showPage' as const)
		: undefined

	return {
		props: {
			pageType,
			header,
			footer,
			page,
			pageLinks,
			seo: {
				canonicalUrl,
				seo: {
					...(dataFromContember.getSettings?.seo ?? {}),
					...Object.fromEntries(
						Object.entries(currentPage.seo ?? {}).filter(([_, value]) => Boolean(value))
					),
				},
			},
		},
		revalidate: 10,
	}
})
