import clsx from 'clsx'
import Image from 'next/image'
import { Fragment, FunctionComponent, useState } from 'react'
import { useParallax } from '../../utils/useParallax'
import type { LinkResult } from '../data/content/LinkFragment'
import type { MediumGalleryResult } from '../data/content/MediumGalleryFragment'
import styles from './Collaboration.module.sass'
import { Container } from './Container'
import { ContemberLink } from './ContemberLink'
import { Title } from './Title'
import { Wysiwyg } from './Wysiwyg'

export type CollaborationProps = {
	title?: string
	description?: string
	images?: MediumGalleryResult
	link?: LinkResult
}

export const Collaboration: FunctionComponent<CollaborationProps> = ({
	title,
	description,
	images,
	link,
}) => {
	const [wrapper, setWrapper] = useState<HTMLDivElement | null>(null)
	const offset = useParallax(wrapper)
	return (
		<div className={styles.wrapper} ref={setWrapper} style={{ '--Collaboration-offset': offset }}>
			<Container>
				{images && <CollaborationImages images={images} view="desktop" position="upper" />}
				{title && (
					<div className={styles.title}>
						<Title heading="secondary" title={title} />
					</div>
				)}
				{images && (
					<>
						<CollaborationImages images={images} view="desktop" position="inner" />
						<CollaborationImages images={images} view="mobile" />
					</>
				)}
				{description && (
					<div className={styles.description}>
						<Wysiwyg source={description} />
					</div>
				)}
				{link && (
					<div className={styles.actions}>
						<ContemberLink link={link}>
							<a className={styles.link}>{link.title}</a>
						</ContemberLink>
					</div>
				)}
			</Container>
		</div>
	)
}

type CollaborationImagesProps = {
	images: MediumGalleryResult
	position?: 'default' | 'upper' | 'inner'
	view?: 'default' | 'mobile' | 'desktop'
}

const CollaborationImages: FunctionComponent<CollaborationImagesProps> = ({
	images,
	position = 'default',
	view = 'default',
}) => {
	return (
		<div className={clsx(styles.images, styles[`is_${view}`], styles[`is_${position}`])}>
			{images.items.map((item, index) => {
				return (
					<Fragment key={item.id}>
						{item.image &&
							(position === 'upper'
								? index >= 1 && index <= 3
								: position === 'inner'
								? index === 0 || index === 4
								: true) && (
								<div
									className={clsx(styles.image, styles[`is_${index + 1}`])}
									style={{
										'--Collaboration-image-width': item.image?.width,
										'--Collaboration-image-height': item.image?.height,
									}}>
									<Image
										src={item.image.url}
										layout="fill"
										objectFit="cover"
										alt={item.image.alt ?? ''}
									/>
								</div>
							)}
					</Fragment>
				)
			})}
		</div>
	)
}
