import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { Icon } from './Icon'
import styles from './ShowPageCarouselNavigationNavigation.module.sass'

export type ShowPageCarouselNavigationProps = {
	onPreviousClick: () => void
	onNextClick: () => void
	isPreviousButtonVisible: boolean
	isNextButtonVisible: boolean
}

export const ShowPageCarouselNavigationNavigation: FunctionComponent<
	ShowPageCarouselNavigationProps
> = ({ onPreviousClick, onNextClick, isPreviousButtonVisible, isNextButtonVisible }) => {
	return (
		<div className={styles.wrapper}>
			<button
				type="button"
				className={clsx(
					styles.button,
					styles.view_previous,
					isPreviousButtonVisible && styles.is_visible
				)}
				onClick={onPreviousClick}
				aria-label="previous">
				<Icon name="arrow" />
			</button>
			<button
				type="button"
				className={clsx(styles.button, styles.view_next, isNextButtonVisible && styles.is_visible)}
				onClick={onNextClick}
				aria-label="next">
				<Icon name="arrow" />
			</button>
		</div>
	)
}
